import Vue from "vue";
import {
  Icon,
  Row,
  Col,
  Form,
  Field,
  Button,
  Dialog,
  Toast,
  Loading,
  PullRefresh,
  List,
  Tag,
  Tab,
  Tabs,
  Search,
  ActionSheet,
  DatetimePicker,
  Popup,
  Empty,
  Cell,
  CellGroup,
} from "vant";

Vue.use(Icon);
Vue.use(Row);
Vue.use(Col);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Loading);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(Tag);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Search);
Vue.use(ActionSheet);
Vue.use(DatetimePicker);
Vue.use(Popup);
Vue.use(Empty);
Vue.use(Cell);
Vue.use(CellGroup);
