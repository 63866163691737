/* eslint-disable import/no-cycle */
import axios from "axios";
import { Dialog } from "vant";
import store from "@/store";
import { getToken } from "@/utils/auth";

const errorCode = {
  401: "认证失败，无法访问系统资源",
  403: "当前操作没有权限",
  404: "访问资源不存在",
  default: "系统未知错误，请反馈给管理员",
};
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 10000,
});

// request拦截器
service.interceptors.request.use(
  config => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false;
    if (getToken() && !isToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${getToken()}`;
    }
    return config;
  },
  error => {
    console.log(error);
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode.default;
    // 二进制数据则直接返回
    if (
      res.request.responseType === "blob" ||
      res.request.responseType === "arraybuffer"
    ) {
      return res.data;
    }
    if (code === 401) {
      Dialog.confirm({
        message: "登录状态已过期，您可以继续留在该页面，或者重新登录",
        title: "系统提示",
      })
        .then(() => {
          store.dispatch("FedLogOut").then(() => {
            // eslint-disable-next-line no-restricted-globals
            location.href = "/index";
          });
        })
        .catch(() => {});
      return Promise.reject(
        new Error("无效的会话，或者会话已过期，请重新登录。")
      );
    }
    if (code !== 200) {
      Dialog.alert({ message: msg });
      return Promise.reject(new Error(msg));
    }
    return res.data;
  },
  error => {
    console.log(`err${error}`);
    let { message } = error;
    if (message === "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = `系统接口${message.substr(message.length - 3)}异常`;
    }
    Dialog.alert({ message });
    return Promise.reject(error);
  }
);

export default service;
