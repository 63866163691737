import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Layout from "../components/Layout/Layout.vue";

Vue.use(VueRouter);

export const routes = [
  {
    path: "/login",
    name: "login",
    hidden: true,
    component: Login,
  },
  {
    path: "",
    component: Layout,
    redirect: "ucenter",
    children: [
      {
        path: "ucenter",
        component: () => import("@/views/UCenter.vue"),
        name: "ucenter",
        meta: { title: "我的", icon: "user" },
      },
    ],
  },
  {
    path: "/scoring-paper",
    name: "scoring-paper",
    hidden: true,
    component: () => import("@/views/exam/scoring/components/ScoringPaper.vue"),
  },
  {
    path: "/view-paper",
    name: "view-paper",
    hidden: true,
    component: () =>
      import("@/views/student/exam-record/components/ViewPaper.vue"),
  },
  {
    path: "/answer-question",
    name: "answer-question",
    hidden: true,
    component: () =>
      import("@/views/student/paper-center/components/AnswerQuestion.vue"),
  },
  {
    path: "/error-paper",
    name: "error-paper",
    hidden: true,
    component: () =>
      import("@/views/student/question-error/components/ErrorPaper.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
