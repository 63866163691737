/**
 * 渲染时间格式
 */
export default function renderTime(time) {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  if (minutes === 0) {
    return `${seconds}秒`;
  }
  return `${minutes}分${seconds}秒`;
}
