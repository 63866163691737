import { render, staticRenderFns } from "./Empty.vue?vue&type=template&id=4e78eacf&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.8_79ca0e5a40703f8280c59fd6beef97f3/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports