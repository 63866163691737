import Vue from "vue";
import Dict from "./Dict";
import { mergeOptions } from "./DictOptions";
import { getDicts } from "@/api/dict";

const options = {
  metas: {
    "*": {
      labelField: "dictLabel",
      valueField: "dictValue",
      request(dictMeta) {
        return getDicts(dictMeta.type).then(res => res.data);
      },
    },
  },
};
mergeOptions(options);
Vue.mixin({
  data() {
    if (
      this.$options === undefined ||
      this.$options.dicts === undefined ||
      this.$options.dicts === null
    ) {
      return {};
    }
    const dict = new Dict();
    dict.owner = this;
    return { dict };
  },
  created() {
    if (!(this.$data.dict instanceof Dict)) {
      return;
    }
    if (typeof options.onCreated === "function") {
      options.onCreated(this.dict);
    }
    this.dict.init(this.$options.dicts).then(() => {
      if (typeof options.onReady === "function") {
        options.onReady(this.dict);
      }
      this.$nextTick(() => {
        this.$emit("dictReady", this.dict);
        if (
          this.$options.methods &&
          this.$options.methods.onDictReady instanceof Function
        ) {
          this.$options.methods.onDictReady.call(this, this.dict);
        }
      });
    });
  },
});
