<template>
  <div class="container">
    <router-view />
    <van-tabbar :value="active" @change="handleChange">
      <van-tabbar-item v-for="v in menus" :key="v.path" :name="v.path">
        <template #icon>
          <svg-icon :icon-class="v.meta.icon" />
        </template>
        <span>{{ v.meta.title }}</span>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Tabbar, TabbarItem } from "vant";

export default {
  name: "Layout",
  components: { [Tabbar.name]: Tabbar, [TabbarItem.name]: TabbarItem },
  computed: {
    ...mapGetters(["menus"]),
    active() {
      const arr = this.$route.path.split("/");
      return `/${arr[1]}`;
    },
  },
  methods: {
    handleChange(v) {
      this.$router.push(v);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  box-sizing: border-box;
  min-height: 100vh;
  background-color: #f0f0f0;
  padding-bottom: 50px;
}
</style>
