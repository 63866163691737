import router from "./index";
import store from "../store";
import { getToken } from "@/utils/auth";

const whiteList = ["/login"];

router.beforeEach((to, from, next) => {
  if (getToken()) {
    /* has token */
    if (to.path === "/login") {
      next({ path: "/" });
    } else if (store.getters.roles.length === 0) {
      // 判断当前用户是否已拉取完user_info信息
      store.dispatch("GetInfo").then(() => {
        store.dispatch("GenerateRoutes").then(accessRoutes => {
          // 根据roles权限生成可访问的路由表
          router.addRoutes(accessRoutes); // 动态添加可访问路由表
          next({ ...to, replace: true }); // hack方法 确保addRoutes已完成
        });
      });
    } else {
      next();
    }
  } else if (whiteList.indexOf(to.path) !== -1) {
    // 没有token
    // 在免登录白名单，直接进入
    next();
  } else {
    next(`/login?redirect=${to.fullPath}`); // 否则全部重定向到登录页
  }
});
