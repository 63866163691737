import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./router/permission";
import "./plugins/dict";
import "./plugins/vant";
import directives from "./directives";
import store from "./store";
import renderTime from "@/utils/renderTime";
import Empty from "@/components/Empty/Empty.vue";
import "./svgicons";

Vue.config.productionTip = false;

Vue.use(directives);

// 全局方法挂载
Vue.prototype.renderTime = renderTime;

// 全局组件挂载
Vue.component("Empty", Empty);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
