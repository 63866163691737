import { render, staticRenderFns } from "./Login.vue?vue&type=template&id=48980b90&scoped=true&"
import script from "./Login.vue?vue&type=script&lang=js&"
export * from "./Login.vue?vue&type=script&lang=js&"
import style0 from "./Login.vue?vue&type=style&index=0&id=48980b90&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.8_79ca0e5a40703f8280c59fd6beef97f3/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48980b90",
  null
  
)

export default component.exports