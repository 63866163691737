<template>
  <van-form @submit="handleSubmit">
    <div class="login-logo">
      <img alt="" src="../assets/logo.svg" />
    </div>
    <van-field
      v-model="form.username"
      label="用户名"
      placeholder="用户名"
      :rules="[{ required: true, message: '请填写用户名' }]"
    />
    <van-field
      v-model="form.password"
      type="password"
      label="密码"
      placeholder="密码"
      :rules="[{ required: true, message: '请填写密码' }]"
    />
    <van-field
      v-model="form.code"
      label="验证码"
      placeholder="验证码"
      :rules="[{ required: true, message: '请填写验证码' }]"
    >
      <img
        slot="extra"
        :src="codeUrl"
        class="login-code-img"
        @click="getCode"
      />
    </van-field>
    <div class="login-btn">
      <van-button
        round
        block
        type="info"
        native-type="submit"
        :loading="submitting"
      >
        登录
      </van-button>
    </div>
  </van-form>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Login",
  data: () => ({
    submitting: false,
    codeUrl: "",
    form: {
      username: "",
      password: "",
      code: "",
      uuid: "",
    },
  }),
  created() {
    this.getCode();
  },
  methods: {
    ...mapActions(["getCodeImg", "Login"]),
    getCode() {
      this.getCodeImg().then(({ img, uuid, captchaOnOff }) => {
        this.captchaOnOff = captchaOnOff == null ? true : captchaOnOff;
        if (this.captchaOnOff) {
          this.codeUrl = `data:image/gif;base64,${img}`;
          this.form.uuid = uuid;
          this.form.code = "";
        }
      });
    },
    handleSubmit() {
      this.submitting = true;
      this.Login(this.form)
        .then(() => {
          this.$toast.success({ message: "登录成功", duration: 1000 });
          this.$router.push({ path: this.redirect || "/" }).catch(() => {});
        })
        .catch(() => {
          if (this.captchaOnOff) {
            this.getCode();
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.login-logo {
  margin: 80px auto 40px;
  text-align: center;
  img {
    width: 130px;
  }
}
.login-btn {
  margin: 16px;
}
.login-code-img {
  height: 24px;
}
</style>
