/* eslint-disable import/no-cycle */
import { routes as constantRoutes } from "@/router";
import { getRouters } from "@/api/menu";
import Layout from "@/components/Layout/Layout.vue";

// 仅指定名称的页面要展示
const allowRouteNames = [
  "Question-error",
  "Exam-record",
  "Paper-center",
  "Scoring",
];
const loadView = view => {
  // 使用 import 实现生产环境的路由懒加载
  return () => import(`@/views/${view}`);
};
function genRoutes(routes, pPath = "") {
  return routes.map(el => {
    if (el.path !== "/") {
      el.path = `${pPath === "/" ? "" : pPath}${
        el.path.indexOf("/") === 0 ? "" : "/"
      }${el.path}`;
    }
    if (typeof el.component === "string") {
      el.component =
        el.component === "Layout" ? Layout : loadView(el.component);
    }
    if (el.children?.length) {
      el.children = genRoutes(el.children, el.path);
    } else {
      delete el.children;
      delete el.redirect;
    }
    return el;
  });
}

export default {
  state: {
    menus: [],
    routes: [],
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.routes = routes;
    },
    SET_MENUS: (state, routes) => {
      state.menus = routes;
    },
  },
  actions: {
    // 生成路由
    GenerateRoutes({ commit }) {
      return new Promise(resolve => {
        // 向后端请求路由数据
        getRouters().then(({ data }) => {
          const rewriteRoutes = genRoutes(
            data
              .filter(
                v =>
                  v.path === "/" &&
                  v.children.some(t => allowRouteNames.indexOf(t.name) > -1)
              )
              .concat(constantRoutes)
          );
          const menus = rewriteRoutes
            .filter(v => !v.hidden)
            .map(v =>
              v.children && v.children.length === 1 ? v.children[0] : v
            );
          commit(
            "SET_ROUTES",
            rewriteRoutes.concat({
              path: "*",
              redirect: "/404",
              hidden: true,
            })
          );
          commit("SET_MENUS", menus);
          resolve(rewriteRoutes);
        });
      });
    },
  },
};
