import mergeRecursive from "@/utils/mergeRecursive";
// eslint-disable-next-line import/no-cycle
import dictConverter from "./DictConverter";

/**
 * 映射字典
 * @param {Object} response 字典数据
 * @param {DictMeta} dictMeta 字典元数据
 * @returns {DictData}
 */
function responseConverter(response, dictMeta) {
  const dicts = response.content instanceof Array ? response.content : response;
  if (dicts === undefined) {
    console.warn(`no dict data of "${dictMeta.type}" found in the response`);
    return [];
  }
  return dicts.map(d => dictConverter(d, dictMeta));
}

export const options = {
  metas: {
    "*": {
      /**
       * 字典请求，方法签名为function(dictMeta: DictMeta): Promise
       */
      request: dictMeta => {
        console.log(`load dict ${dictMeta.type}`);
        return Promise.resolve([]);
      },
      /**
       * 字典响应数据转换器，方法签名为function(response: Object, dictMeta: DictMeta): DictData
       */
      responseConverter,
      labelField: "label",
      valueField: "value",
    },
  },
  /**
   * 默认标签字段
   */
  DEFAULT_LABEL_FIELDS: ["label", "name", "title"],
  /**
   * 默认值字段
   */
  DEFAULT_VALUE_FIELDS: ["value", "id", "uid", "key"],
};

export function mergeOptions(src) {
  mergeRecursive(options, src);
}

export default options;
