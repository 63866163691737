/* eslint-disable import/no-cycle */
import { login, logout, getInfo, getCodeImg } from "@/api/login";
import { setToken, removeToken } from "@/utils/auth";

export default {
  state: {
    info: {},
    name: "",
    roles: [],
    permissions: [],
  },

  mutations: {
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_INFO: (state, info) => {
      state.info = info;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    },
  },

  actions: {
    // 获取登录验证码
    getCodeImg,
    // 登录
    Login(context, userInfo) {
      const username = userInfo.username.trim();
      const { password } = userInfo;
      const { code } = userInfo;
      const { uuid } = userInfo;
      return login(username, password, code, uuid).then(res => {
        setToken(res.token);
        return res;
      });
    },

    // 获取用户信息
    GetInfo({ commit }) {
      return getInfo().then(res => {
        commit("SET_NAME", res.user.userName);
        commit("SET_INFO", res.user);
        if (res.roles && res.roles.length > 0) {
          // 验证返回的roles是否是一个非空数组
          commit("SET_ROLES", res.roles);
          commit("SET_PERMISSIONS", res.permissions);
        } else {
          commit("SET_ROLES", ["ROLE_DEFAULT"]);
        }
        return res;
      });
    },

    // 退出系统
    LogOut({ dispatch, state }) {
      return logout(state.token).then(() => dispatch("FedLogOut"));
    },

    // 前端 登出
    FedLogOut({ commit }) {
      commit("SET_NAME", "");
      commit("SET_INFO", {});
      commit("SET_ROLES", []);
      commit("SET_PERMISSIONS", []);
      removeToken();
    },
  },
};
